import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Page, Card, Text, Link, EmptyState } from '@shopify/polaris'

const ErrorPage = () => {
  const { t } = useTranslation('errorPage')

  return (
    <Page>
      <Card>
        <EmptyState
          heading={t('title')}
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <Text as="p" variant="bodyMd">
            <Trans
              i18nKey="errorPage:description"
              components={{
                adminLink: <Link url="mailto:caesarliu@wavenet.com.tw" external>{t('admin')}</Link>
              }}
            />
          </Text>
        </EmptyState>
      </Card>
    </Page>
  )
}

export default ErrorPage
