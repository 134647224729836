import React from 'react'
import { Routes, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import CallbackPage from './pages/CallbackPage'
import AdminLoginPage from './pages/admin/AdminLoginPage'
import AdminPage from './pages/admin/AdminPage'
import TaskPage from './pages/admin/TaskPage'
import ErrorPage from './pages/ErrorPage'
import Main from './pages/Main'
import ProtectedRoute from './components/ProtectedRoute'
import Privacy from './pages/Privacy'
import History from './pages/History'
import Help from './pages/Help'
import Pricing from './pages/Pricing'
import SoockerEntry from './pages/SoockerEntry'
import WooCommerceLoginPage from './pages/WooCommerceLoginPage'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const AdminPageHelmet = ({ children }) => {
  const hostname = window.location.hostname

  let title = 'ORCA App 管理員後台'
  if (hostname.includes('dev')) {
    title = 'DEV ' + title
  } else if (hostname === 'localhost') {
    title = 'LOCAL ' + title
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  )
}

const DefaultHelmet = ({ children }) => {
  const hostname = window.location.hostname

  let title = 'ORCA Analytics & Marketing Automation Service'
  if (hostname.includes('dev')) {
    title = 'DEV ' + title
  } else if (hostname === 'localhost') {
    title = 'LOCAL ' + title
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  )
}

export default function App () {
  return (
    <div>
      <Routes>
        <Route path='/login' element={<DefaultHelmet><LoginPage /></DefaultHelmet>} />
        <Route path='/callback' element={<DefaultHelmet><CallbackPage /></DefaultHelmet>} />
        <Route path='/soocker/login' element={<DefaultHelmet><SoockerEntry /></DefaultHelmet>} />
        <Route path='/admin/login' element={<DefaultHelmet><AdminLoginPage /></DefaultHelmet>} />
        <Route path='/admin' element={<AdminPageHelmet><AdminPage /></AdminPageHelmet>} />
        <Route path='/admin/tasks' element={<AdminPageHelmet><TaskPage /></AdminPageHelmet>} />
        <Route path='/error' element={<DefaultHelmet><ErrorPage /></DefaultHelmet>} />
        <Route path='/privacy' element={<DefaultHelmet><Privacy /></DefaultHelmet>} />
        <Route path='/pricing' element={<DefaultHelmet><Pricing /></DefaultHelmet>} />
        <Route path='/help' element={<DefaultHelmet><Help /></DefaultHelmet>} />
        <Route path='/history' element={<DefaultHelmet><History /></DefaultHelmet>} />
        <Route path='/woo_commerce_login' element={<DefaultHelmet><WooCommerceLoginPage /></DefaultHelmet>} />
        <Route path='/' element={
          <ProtectedRoute>
            <DefaultHelmet>
              <Main />
            </DefaultHelmet>
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  )
}

AdminPageHelmet.propTypes = {
  children: PropTypes.node.isRequired
}

DefaultHelmet.propTypes = {
  children: PropTypes.node.isRequired
}
