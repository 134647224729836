import { React, useState, useEffect } from 'react'
import { LegacyCard, Layout, Text, Button, Badge, Banner, BlockStack, InlineStack, SkeletonBodyText, ButtonGroup, Select } from '@shopify/polaris'
import { authInstance, getOrcaHistory, showTime } from '../helper'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

const Page0 = ({ handleTabChange, isPaid, trialDayLeft, isOrcaXAida }) => {
  const [orcaUrl, setOrcaUrl] = useState('')
  const [maUrl, setMaUrl] = useState('')
  // const [history, setHistory] = useState('')
  const [lastUpdate, setLastUpdate] = useState(' ')
  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('success')
  // const [isSettingDone, setIsSettingDone] = useState(null)
  const [projectState, setProjectState] = useState(-1)
  const [isShowRecom, setIsShowRecom] = useState(false)
  const initialOrcaLanguage = localStorage.getItem('orcaLanguage') || 'zh-TW'
  const [orcaLanguage, setOrcaLanguage] = useState(initialOrcaLanguage)
  const { t, i18n } = useTranslation('page0')

  const getOrcaUrl = async () => {
    const res = await authInstance.get('/get_orca_url', {})
    setOrcaUrl(res.data.orca_analytics_url)
    setMaUrl(res.data.orca_ma_url)
  }

  const getAidaSetting = async () => {
    try {
      const res = await authInstance.get('/aida/settings', {})
      setIsShowRecom(res.data.is_active)
    } catch (err) {
      console.log('getAidaSetting err:', err)
    }
  }

  const anyncForUseEffect = async () => {
    const res = await authInstance.get('/get_shop_info')
    // 拿存在db的語系。如果沒有設定過，就偵測瀏覽器的語系
    // app db 和 BM db存：comp_default_language: 'zh-tw'(小寫), 'en', 'jp'
    const orcaLanguage = res.data.comp_default_language
    console.log('get shop info res: ', res, 'orcaLanguage from res: ', orcaLanguage)
    if (orcaLanguage) {
      if (orcaLanguage === 'zh-tw') {
        i18n.changeLanguage('zh-TW')
      } else {
        i18n.changeLanguage(orcaLanguage)
      }
    }
    /*
      0 = 基本資料未設定 -> 只有在未設定完成時才顯示「開始設定」
      1 = 基本資料已設定
      2 = 自動拋轉資料到 ORCA 進行中
      3 = 自動拋轉資料到 ORCA 完成
      4 = 已送出分析請求但未完成
      5 = 分析完成
      */
    /*
    if (res.data.orca_state === null) {
      setIsSettingDone(false)
    } else {
      const orcaState = res.data.orca_state
      if (orcaState === 0) {
        setIsSettingDone(false)
        return
      }
      setIsSettingDone(true)
    }
    */

    let orcaState = 0
    let bmState = 0
    // let res = await authInstance.get('/get_shop_info')
    /*
    orcaState
    0 = 基本資料未設定
    1 = 基本資料已設定
    2 = 自動拋轉資料到 ORCA 進行中
    3 = 自動拋轉資料到 ORCA 完成
    4 = 已送出分析請求但未完成
    5 = 分析完成

    bmState
    0 = 未建站
    1 = 已建站
    2 = 資料正確

    ProjectState
    0 = 基本資料未填 orca=0
    1 = 商品/客戶資料未上傳 orca=1
    2 = 商品/客戶資料上傳中，請等待 orca=2
    3 = 資料上傳完成，但尚未建站，或客戶/商品資料格式尚未被人工確認   orca>2, bm<2
    4 = 可進行分析(資料上傳完成、已建站、客戶/商品資料格式正確，尚未進行第一次分析) orca=3, bm=2
    5 = 資料分析中 orca=4, bm=2
    6 = 可進行分析(資料上傳完成、已建站、客戶/商品資料格式正確，至少進行過一次分析) orca=5, bm=2
    */
    if (res.data.orca_state === null) {
      setProjectState(0) // 首先判斷 基本資料填了沒
      return
    } else {
      // setOrcaState(res.data.orca_state)
      orcaState = res.data.orca_state
      if (res.data.orca_state === 0) {
        setProjectState(0)
        return
      }
    }
    if (res.data.bm_status !== null) {
      // setBmState(res.data.bm_status)
      bmState = res.data.bm_status
    }
    let tempState = 0
    if (orcaState === 1) {
      tempState = 1
    } else if (orcaState === 2) {
      tempState = 2
    } else if (orcaState > 2) {
      if (bmState < 2) {
        tempState = 3
      } else if (bmState === 2) {
        if (orcaState === 3) {
          tempState = 4
        } else if (orcaState === 4) {
          tempState = 5
        } else if (orcaState === 5) {
          tempState = 6
        }
      }
    }
    if (tempState > 3) {
      getOrcaUrl()
      handleOrcaHistory()
    }
    setProjectState(tempState)
  }

  useEffect(() => {
    getOrcaUrl()
    handleOrcaHistory()
    anyncForUseEffect()
    if (isOrcaXAida) { getAidaSetting() }
  }, [])
  /*
  const handleInfo = async () => {
    console.log('handleInfo ++')

    console.log('handleInfo --')
  }
  */

  const handleGotoMa = () => {
    if (maUrl === '') {
      setMessage(t('sorry'))
      setStatus('warning')
      setOpenSuccessBanner(true)
    } else {
      // window.location.href = maUrl
      window.open(maUrl, '_blank')
    }
  }

  const handleGotoOrca = () => {
    if (orcaUrl === '') {
      setMessage(t('Sorry'))
      setStatus('warning')
      setOpenSuccessBanner(true)
    } else {
      // window.location.href = orcaUrl
      window.open(orcaUrl, '_blank')
    }
  }

  /*
  const handleTest = async () => {
    const res = await authInstance.get('/temp_test', {})
    // const res = await authInstance.get('/shopline/login', {})
    // const res = await authInstance.get('/create_shopify_subscription', { params: { mode: 'test' } }) // 'month', 'year'
    console.log(res)
    // window.location.replace(res.data)
  }
  */

  const handleOrcaHistory = async () => {
    const res = await getOrcaHistory()
    const temphis = res.data.data
    // await setHistory(res.data.data)
    if (temphis.length > 0) {
      setLastUpdate(showTime(temphis[0].edit_time))
    } else {
      setLastUpdate(t('no_record'))
    }
  }

  const handleClick = (url) => {
    window.location.href = url
  }

  const options = [
    { label: '繁體中文', value: 'zh-TW' },
    { label: 'English', value: 'en' },
    { label: '日本語', value: 'jp' }
  ]

  // 此語系設定與後續其他orca服務的語系相關，只讓user第一次做設定，之後就不再開放更改。用來存BM comp_default_language
  const handleSelectOrcaLanguage = (value) => {
    setOrcaLanguage(value)
    i18n.changeLanguage(value)
    localStorage.setItem('orcaLanguage', value)
  }

  return (
    <div>
      <LegacyCard.Section>
        <div className='mb-4'>
          {openSuccessBanner && <Banner
            title= {message}
            status={status}
            onDismiss={() => {
              setOpenSuccessBanner(false)
            }}
          />}
        </div>
        <Layout.Section>
          <Layout>
              <Text as="h1" variant="heading3xl" >
                {t('welcome')}
              </Text>
              <Badge>
                {(isPaid === true) && <div>
                  <Text as="h2" variant="bodyMd">
                    {t('paid')}
                  </Text>
                </div>}
                {(isPaid === false) && <div>
                  <Text as="h2" variant="bodyMd">
                    {/* 免費版 (試用期剩餘{trialDayLeft}天) */}
                    {/* {t('free')} {t('trial_day_left', { trialDayLeft })} */}
                    {
                      trialDayLeft > 0 && (
                        <Text as="h2" variant="bodyMd">
                          {t('trial')}
                        </Text>
                      )
                    }
                    {
                      trialDayLeft <= 0 && (
                        <Text as="h2" variant="bodyMd">
                          {t('free')}
                        </Text>
                      )
                    }
                  </Text>
                </div>}
              </Badge>
              <LegacyCard.Section>
              <Button onClick={handleClick.bind(null, '/pricing')}>{t('learn_pricing')}</Button>
              {/* <Button onClick={handleTest}>測試用按紐，若正式平台看到這顆按紐，請通知Leo把它拿掉</Button> */}
              </LegacyCard.Section>
          </Layout>
          {
            projectState === -1 &&
              <LegacyCard.Section>
                <SkeletonBodyText lines={5}/>
              </LegacyCard.Section>
          }
          {
            projectState === 0 && (
              <div style={{ color: '#000', background: '#ABF' }}>
                <LegacyCard.Section>
                  <BlockStack gap="5" inlineAlign="start">
                    <Text as="h2" variant="headingXl">
                      {t('first_installation')}
                    </Text>
                      <div><br/>
                        <Text as="h5" variant="headingMd">
                          {t('select_orca_language')}
                        </Text>
                        <Text as="h5" variant="headingMd">
                          {t('select_orca_language_2')}
                        </Text>
                        <Select
                          label={t('select_orca_language_label')}
                          // labelInline
                          options={options}
                          onChange={handleSelectOrcaLanguage}
                          value={orcaLanguage}
                          disabled={projectState !== 0}
                        />
                      <br/></div>
                    <Button onClick={() => {
                      handleTabChange(1)
                      localStorage.setItem('orcaLanguage', orcaLanguage)
                    }}
                      tone="success" variant="primary">
                      {t('start_setup')}
                    </Button>
                  </BlockStack>
                </LegacyCard.Section>
              </div>
            )
          }
          {(projectState === 1) &&
            <LegacyCard.Section>
              <BlockStack gap="5" inlineAlign="center">
                <Text variant="headingSm">
                  {t('please_synchronize')}
                </Text>
                <Button onClick={() => handleTabChange(2)}>{t('synchronize_product_data')}</Button>
              </BlockStack>
            </LegacyCard.Section>
          }
          {(projectState === 2 || projectState === 3) &&
            <LegacyCard.Section>
              <BlockStack gap="5" inlineAlign="start">
                <Text as="h1" variant="headingXl">
                  {t('under_construction')}
                </Text>
                <BlockStack gap="2">
                  <Text as="p" variant="bodyLg">
                    {t('please_be_patient')}
                  </Text>
                  <Text as="p" variant="bodyLg">
                    {t('will_notify')}
                  </Text>
                </BlockStack>
              </BlockStack>
            </LegacyCard.Section>
          }
          {
            projectState > 3 && (
              <>
              <LegacyCard.Section>
                <BlockStack gap="5" inlineAlign="start">
                  <Text as="h2" variant="headingXl">
                    {t('orca_activated')}
                  </Text>
                  {isPaid && <Text as="p" variant="bodyLg">
                    {t('last_time_analysis')} {lastUpdate + ' '}
                  </Text>}
                </BlockStack>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <BlockStack gap="5" inlineAlign="start">
                  <Text as="h2" variant="heading2xl">
                    {t('use_now')}
                  </Text>
                  <ButtonGroup>
                    <Button onClick={handleGotoMa}>{t('orca_marketing_automation')}</Button>
                    <Button onClick={handleGotoOrca}>{t('orca_data_analysis')}</Button>
                    {(isPaid === true) &&
                      <Button onClick={() => handleTabChange(4)}>{t('manually_execute')}</Button>
                    }
                  </ButtonGroup>
                </BlockStack>
              </LegacyCard.Section>
              {isOrcaXAida &&
              <LegacyCard.Section>
                <BlockStack gap="5" inlineAlign="start">
                  <Text as="h2" variant="heading2xl">
                    {t('products_recommendation')}：{isShowRecom ? t('enabled') : t('disabled')}
                  </Text>
                  {isPaid || (trialDayLeft > 0)
                    ? (
                      <Text as="p" variant="bodyLg">
                        {t('Automatically_update')}
                      </Text>)
                    : null}
                  <InlineStack align="start">
                    <Button onClick={() => handleTabChange(5)}>{t('change_settings')}</Button>
                  </InlineStack>
                </BlockStack>
              </LegacyCard.Section>}
              </>
            )
          }
            </Layout.Section>
      </LegacyCard.Section>
    </div>
  )
}

Page0.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  isPaid: PropTypes.bool.isRequired,
  trialDayLeft: PropTypes.number.isRequired,
  isOrcaXAida: PropTypes.bool.isRequired
}

export default Page0
