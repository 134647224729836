import React, { useState, useEffect } from 'react'
import { Pagination } from 'antd'
import { Button, LegacyCard, Text, DataTable, SkeletonBodyText, BlockStack, InlineStack, Toast } from '@shopify/polaris'
import DropZoneComponents from '../components/DropZone'
import { authInstance } from '../helper'
import EditSegmentsModal from '../components/EditSegmentsModal'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

const Page2 = ({ handleTabChange, isPaid, trialDayLeft }) => {
  const shop = String(window.localStorage.getItem('shop'))
  const { t } = useTranslation('page2')
  const [rowData, setRowData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const defaultPageSize = 10
  // const [crmFile, setCrmFile] = useState()
  const [itemFile, setItemFile] = useState('')
  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const [message, setMessage] = useState('')
  const [projectState, setProjectState] = useState(-1)
  const [showEditSegmentsModal, setShowEditSegmentsModal] = useState(false)
  // const colTitle = ['item_desc', 'inventory_item_id', 'item_publish_date', 'item_copy', 'segment1', 'segment2', 'segment3']
  const isShopline = shop.includes('shopline')
  const isPaidOrNotShopline = (isPaid || !isShopline)
  const colTitle = (isPaidOrNotShopline)
    ? isShopline
      ? [t('product_name'), t('product_number'), t('listing_time'), t('primary_category'), t('sub_category_1'), t('sub_category_2'), t('sub_category_3')]
      : [t('product_name'), t('product_number'), t('listing_time'), t('product_series'), t('main_category'), t('sub_category'), t('small_category')]
    : [t('product_name'), t('product_number'), t('listing_time'), t('primary_category'), t('sub_category_1'), t('sub_category_2')]
  const colType = (isPaidOrNotShopline)
    ? ['text', 'text', 'text', 'text', 'text', 'text', 'text']
    : ['text', 'text', 'text', 'text', 'text', 'text']

  const isPageActive = isPaid || (trialDayLeft > 0)

  const uploadFiles = async () => {
    if (itemFile === '') {
      setMessage(t('upload_file'))
      setOpenSuccessBanner(true)
      return
    }
    const formData = new FormData()
    // formData.append('crmFile', crmFile)
    formData.append('itemFile', itemFile)
    setMessage(t('creating_tasks'))
    setOpenSuccessBanner(true)
    await authInstance.post('/reports/upload-to-shopify', formData)
    setMessage(t('tasks_scheduled'))
    setOpenSuccessBanner(true)
  }

  const downloadFiles = async () => {
    setMessage(t('creating_tasks'))
    setOpenSuccessBanner(true)
    await authInstance.get('/reports/download', {
      params: {
        types: 'item'
      }
    })
    setMessage(t('preparing'))
    setOpenSuccessBanner(true)
  }

  const anyncForUseEffect = async () => {
    const res = await authInstance.get('/get_shop_info')
    /*
    0 = 基本資料未設定
    1 = 基本資料已設定
    2 = 自動拋轉資料到 ORCA 進行中
    3 = 自動拋轉資料到 ORCA 完成
    4 = 已送出分析請求但未完成
    5 = 分析完成
    */
    let tempState = 0
    if (res.data.orca_state === null) {
      setProjectState(0)
      return
    } else {
      const orcaState = res.data.orca_state
      if (orcaState === 0) {
        setProjectState(0)
        return
      } else if (orcaState === 1) {
        tempState = 1
      } else if (orcaState === 2) {
        tempState = 2
      } else if (orcaState === 3) {
        tempState = 2
      } else if (orcaState === 4) {
        tempState = 2
      } else if (orcaState === 5) {
        tempState = 2
      }
    }
    if (isPaid === false) {
      if (tempState === 2) {
        tempState = 1
      }
    }
    if (tempState > 0) {
      await refreshProductData()
    }
    setProjectState(tempState)
  }

  const refreshProductData = async () => {
    const res = await authInstance.get(`/preview_items?page=${currentPage}&per_page=20`)
    setTotal(res.data.length)
    setRowData(res.data.slice((currentPage - 1) * defaultPageSize, currentPage * defaultPageSize))
  }

  useEffect(() => {
    anyncForUseEffect()
  }, [])

  useEffect(() => {
    refreshProductData()
  }, [currentPage])

  const getCategoryDescriptionByShop = () => {
    if (shop.includes('shopline')) {
      return (<Text as="p" variant="bodyLg">
        {t('product_category_hint_shopline')}
      </Text>)
    } else if (shop.includes('shopify')) {
      return (<>
        <Text as="p" variant="bodyLg">
          {t('product_category_hint_shopify')}
        </Text>
      </>)
    } else if (shop.includes('cyberbiz')) {
      return (<>
        <Text as="p" variant="bodyLg">
          {t('product_category_hint_cyberbiz')}
        </Text>
      </>)
    }
    return (<>
      <Text as="p" variant="bodyLg">
        {t('product_category_hint_default')}
      </Text>
    </>)
  }

  return (
    <div>
      {showEditSegmentsModal && (<EditSegmentsModal
        active={showEditSegmentsModal}
        setActive={setShowEditSegmentsModal}
        refreshProductData={refreshProductData}
      />)}
      {(projectState === -1) &&
        <LegacyCard.Section>
          <SkeletonBodyText lines={10}/>
        </LegacyCard.Section>
      }
      {(projectState === 0) &&
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="center">
            <Text variant="headingSm">
              {t('basic_info_missing')}
            </Text>
            <Button onClick={() => handleTabChange(1)}>{t('fill_in_basic_information')}</Button>
          </BlockStack>
        </LegacyCard.Section>
      }
      {(projectState >= 1) && <div>
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="start">
            <Text as="h1" variant="headingXl">
              {t('confirm_product_category')}
            </Text>
            <BlockStack gap="2">
              {getCategoryDescriptionByShop()}
            </BlockStack>
            <BlockStack gap="2">
              <Text as="p" variant="bodyLg">
                <strong>{t('product_category_hint_ps')}</strong>
              </Text>
            </BlockStack>
          </BlockStack>
          <InlineStack align="end">
            {(shop.includes('cyberbiz') || shop.includes('shopline')) && (<span className="mt-2 mr-2"><Button onClick={() => setShowEditSegmentsModal(true)} disabled={!isPageActive}>{t('edit_category')}</Button></span>)}
            <span className="mt-2"><Button onClick={downloadFiles} disabled={!isPageActive}>{t('download_product_data')}</Button></span>
          </InlineStack>
        </LegacyCard.Section>
        <LegacyCard.Section>
          {/* shopline 試用版只需要顯示主分類、子分類 1、子分類 2，但是後端回傳回來的資料有 4 個分類，所以特別處理 */}
          <DataTable
            columnContentTypes={colType}
            headings={colTitle}
            rows={rowData.map(row => {
              if (isPaidOrNotShopline) {
                return row
              } else {
                return row.slice(0, -1)
              }
            })}
            increasedTableDensity={true}
          />
          <InlineStack align="end">
            <Pagination
              current={currentPage}
              defaultPageSize={defaultPageSize}
              total={total}
              showSizeChanger={false}
              onChange={(page, pageSize) => {
                setCurrentPage(page)
              }}
            />
          </InlineStack>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <DropZoneComponents title={t('dropzone_title')} file={itemFile} setFile={setItemFile} isDisabled={!isPageActive}/>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <InlineStack align='center'>
            <Button onClick={uploadFiles} disabled={!isPageActive}>{t('save')}</Button>
          </InlineStack>
          <InlineStack align="end">
            <Button onClick={() => handleTabChange(3)}>{t('next_step')}</Button>
          </InlineStack>
        </LegacyCard.Section>
        {openSuccessBanner &&
          <Toast content={message} duration={8000}
            onDismiss={() => { setOpenSuccessBanner(false) }} />}
      </div>
      }
    </div>
  )
}

Page2.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  isPaid: PropTypes.bool.isRequired,
  trialDayLeft: PropTypes.number.isRequired
}
export default Page2
